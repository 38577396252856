import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'

const priceRules = [
  v => /^[0-9]+\.{0,1}[0-9]{0,2}$/.test(v) || this.$t('pls_enter_the_correct_price'),
]

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      requiredNew: false,
    },
  },
  PLZArea: {
    displayName: 'plzArea',
  },
  name: {
    displayName: 'delivname',
  },
  priceMod: {
    type: IKDataEntity.Types.Float,
    displayName: 'priceMod',
    formConfig: {
      rule: priceRules,
    },
  },
  freePrice: {
    type: IKDataEntity.Types.Float,
    displayName: 'freePrice',
    formConfig: {
      rule: priceRules,
    },
  },
  startPrice: {

    type: IKDataEntity.Types.Float,
    displayName: 'startPrice',
    formConfig: {
      rule: priceRules,
    },
  },

}
const config = {
  add: function (item) {
    return hillo.post('Takeaway.php?op=addDeliveryArea', item, {
      showLoading: true,
    })
  },
  edit: function (item) {
    return hillo.postWithUploadFile('Takeaway.php?op=updateDeliveryArea', item, {
      showLoading: true,
    })
  },
  remove: function (id) {
    return hillo.post('Takeaway.php?op=deleteDeliveryArea', { id: id })
  },
  load: async function (filter) {
    return (await hillo.get('Takeaway.php?op=showAllDeliveryArea', { ...filter }))
      .content
  },
}
export default IKDataEntity.ModelFactory(entity, config)
