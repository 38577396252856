<template>
  <ik-data-table
    :entity-name="$t('distributionDistance')"
    :model="model"
    icon="mdi-truck"
  />
</template>

<script>

  import { IkDataTable } from 'metaflow-js'
  import DeliveryRange from '../../../../model/takeaway/DeliveryRange'

  export default {
    components: {
      IkDataTable,
    },
    data: function () {
      return ({
        model: DeliveryRange,
      })
    },
  }
</script>
